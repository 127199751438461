<template>
    <div>
		<b-modal ref="modalAddSaillie" size="xl" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.add_saillie") }}
				</template>
			</template>

			<div class="col-12">
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('global.date') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-datepicker class="col" v-model="acte_day" mode="date"></e-datepicker>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-3 col-form-label">{{ $t('monte.type_saillie') }} *</label>
					<div class="col-9">
						<div class="row">
							<e-select
                                v-model="selected_acte_type"
                                id="actestype_id"
                                track-by="actestype_id"
                                label="actestype_label"
                                :placeholder="$t('acte.selectionnez_acte')"
                                :selectedLabel="$t('global.selected_label')"
                                :options="actes_type_saillie"
                                :searchable="true"
                                :allow-empty="false"
                                :loading="loading_acte_type"
                                :show-labels="false"
                                :group-select="false"
	                   		 	:class="{ 'is-invalid': error }"
                            >
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
						</div>
					</div>
				</div>

				<CustomTable
					id_table="planning_season_mare"
					ref="planning_season_mare"
					:busy.sync="table_busy"
					:externSlotColumns="extern_slot_columns"
					primaryKey="sms_id"
					:hide_if_empty="true"
					:items="couples"
					:preselected_items="preselected_couples"
					:display_action_button="false"
				>
					<template v-slot:[`custom-slot-cell(chaleur)`]="{ data }">
						<input type="text" class="form-control" v-model="data.chaleur" @input="changeNumChaleur(data.sms_id, data.chaleur)"/>
					</template>
					<template v-slot:[`custom-slot-cell(saillie_time)`]="{ data }">
						<e-datepicker v-model="data.acte_time" mode="time" @input="changeTime(data.sms_id, data.acte_time)"></e-datepicker>
					</template>
					<template v-slot:[`custom-slot-cell(mouvement)`]="{ data }">
						<div class="custom-control custom-checkbox">
							<b-form-checkbox 
								name="check-button"
	                            switch 
								v-model="data.mouvement" 
								@change="changeMouvement(data.sms_id, data.mouvement)"
							>
							</b-form-checkbox>
						</div>
					</template>
				</CustomTable>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Gynecologie from "@/mixins/Gynecologie.js"
import Common from '@/assets/js/common'
import ActeType from '@/mixins/ActeType.js'
import Actes from '@/mixins/Actes.js'
import Mouvements from '@/mixins/Mouvements.js'
import _uniqueId from 'lodash/uniqueId'
import { EventBus } from 'EventBus'

export default {
	name: "ModalAddSaillieBeta",
	mixins: [Gynecologie, ActeType, Actes, Mouvements],
	props: ['date', 'planning', 'season_id', 'residence', 'type'],
	data () {
		return {
			processing: false,
			table_busy: false,
			acte_day: new Date(),
			couples: [],
			selected_acte_type: null,
			preselected_couples: [],
			actes_type_saillie: [],
			loading_acte_type: false,
			extern_slot_columns: [
				'saillie_time',
				'mouvement',
				'chaleur'
			],
			error: false
		}
	},
	methods: {
		async init_component() {
			this.acte_day = this.date
			this.couples = []

			this.table_busy = true

			if(this.planning.infos) {
				for(let i in this.planning.infos) {
	                for(let y in this.planning.infos[i]) {
	                    await Common.asyncForEach(this.planning.infos[i][y], async (info) => {
	                    	if(info.mare && info.stallion) {
								const last_num_chaleur = await this.getLastNumChaleur(info.mare.horse_id, new Date())
		                        this.couples.push({
									sms_id: info.sms ? info.sms.seasonmarestallion_id : _uniqueId('sms'),
									acte_time: new Date(),
									stallion_name: info.stallion.horse_nom,
									stallion_id: info.stallion.horse_id,
									mare_name: info.mare.horse_nom,
									mare_id: info.mare.horse_id,
									mouvement: false,
									chaleur: last_num_chaleur,
									last_num_chaleur: last_num_chaleur
								})
		                    }
	                    })
	                }
	            }
			}
			else {
				for(let i in this.planning.planning_schedule) {
                    await Common.asyncForEach(this.planning.planning_schedule[i].infos, async (info) => {
                    	if(info.mare && info.stallion) {
							const last_num_chaleur = await this.getLastNumChaleur(info.mare.horse_id, new Date())
    	                    this.couples.push({
								sms_id: info.sms ? info.sms.seasonmarestallion_id : _uniqueId('sms'),
								acte_time: new Date(),
								stallion_name: info.stallion.horse_nom,
								stallion_id: info.stallion.horse_id,
								mare_name: info.mare.horse_nom,
								mare_id: info.mare.horse_id,
								mouvement: false,
								chaleur: last_num_chaleur,
								last_num_chaleur: last_num_chaleur
							})	
    	                }
                    })
	            }
			}
			// this.$refs.planning_season_mare.isCheckedAll = true //TODO
			this.preselected_couples = this.couples.filter(couple => {
				return this.preselected_mares.find(id => id == couple.mare_id)
			})
			this.table_busy = false
			
			this.loading_acte_type = true
			this.actes_type_saillie = await this.getActeTypeSaillie()
			if(this.type){
				this.selected_acte_type = this.actes_type_saillie.find(actestype => actestype.actestype_code == this.type)
			}
			this.loading_acte_type = false
		},
		openModal() {
			this.$refs.modalAddSaillie.show()
		},
		closeModal() {
			this.$refs.modalAddSaillie.hide()
		},
		async checkForm() {
			this.processing = true
			this.error = false

			if(!this.selected_acte_type) {
				this.processing = false
				this.error = true
				return false
			}

			const questions = await this.getQuestionsByActeType(this.selected_acte_type.actestype_id)
			const question_stallion = questions.find(quest => quest.question_code == "Étalon")
			const question_chaleur = questions.find(quest => quest.question_code == "Numéro de chaleur")

			const selected = this.$refs.planning_season_mare.selected

			await Common.asyncForEach(selected, async (sms_id) => {
				const couple = this.couples.find(couple => couple.sms_id == sms_id)

				let acte_date = new Date(this.acte_day.getFullYear() + '/' + parseInt(this.acte_day.getMonth()+1) + '/' + this.acte_day.getDate())
				acte_date.setHours(couple.acte_time.getHours())
				acte_date.setMinutes(couple.acte_time.getMinutes())

				if(sms_id.toString().includes('sms')) {
					sms_id = await this.getOrCreateSeasonMareStallionHorse(couple.mare_id, couple.stallion_id, acte_date)
				}

				const reponses = [
					{
						reponse_data: [couple.stallion_name],
						reponse_data_hidden: {
							type: "season_mare_stallion",
							id: sms_id
						},
						reponse_question: question_stallion.question_id
					},
					{
						reponse_data: [couple.chaleur],
						reponse_question: question_chaleur.question_id
					}
				]

				let acte_status = 1
				if(acte_date > new Date()) {
					acte_status = 0
				}

				await this.addActes([couple.mare_id], acte_status, acte_date, '', this.selected_acte_type.actestype_id, undefined, null, [], reponses, null, null)

				if(couple.mouvement) {
					await this.createEntreSortie(couple.mare_id, this.residence, acte_date)
				}
			})
			.then(async () => {
				await this.$sync.force(true, true)
				this.processing = false
				this.closeModal()
				EventBus.$emit('PlanningMonte::refresh')
			})
		},

		changeTime(couple_id, time) {
			const couple = this.couples.findIndex(couple => couple.sms_id == couple_id)
			this.couples[couple].acte_time = time
		},

		changeMouvement(couple_id, mouvement) {
			const couple = this.couples.findIndex(couple => couple.sms_id == couple_id)
			this.couples[couple].mouvement = !mouvement
		},

		changeNumChaleur(couple_id, numChaleur){
			const couple = this.couples.findIndex(couple => couple.sms_id == couple_id)
			this.couples[couple].chaleur = numChaleur
		}
	},
	computed: {
		preselected_mares: function(){
			// On récupère tous les IDs des juments ayant été saillies en acte
			// Monte en main
			if(this.planning.infos){
				return Object.entries(this.planning.infos).map(entry => {
					if (entry.length){
						return Object.entries(entry[1]).flat(2).filter(e => e.saillie !== "green")
					}
				}).flat().map(resa => resa.mare?.horse_id)
			} 
			// Monte en IA
			else if(this.planning.planning_schedule){
				return this.planning.planning_schedule.map(e => e.infos).flat().filter(e => e.saillie !== "green")
				.map(resa => resa.mare?.horse_id)
			} else {
				return []
			}
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
	}
}
</script>